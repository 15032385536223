.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; /* Adjust margin between rows */
  }
  
  .image-container,
  .card {
    flex: 1;
    margin: 0 10px; /* Adjust margin between image/card and card/image */
  }
  
  .card {
    max-width: calc(90% - 20px); /* Adjust width of card */
    padding: 20px 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: linear-gradient(135deg, #4c4d4b, #939692);
    overflow-y: auto;
    position: relative; /* Add position relative to contain the title */
  }
  
  .card-title {
    position: absolute;
    top: 0;
    left: 30px;
    z-index: 1;
    background-color: #fff;
    font-weight: bold;
    padding: 0 10px;
    border-radius: 5px;
  }
  
  .card::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  
  .card-content {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #fdf6f6;
  }
  
  .image-container img {
    max-width: 100%; /* Ensure the image fits within its container */
    border-radius: 10px; /* Add rounded corners to the image */
    align-items: center;
  }
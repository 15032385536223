.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card,
.login-form {
  width: 300px;
  border-radius: 5px;
  text-align: center;
}

.card {
  border: 1px solid #ccc;
  padding: 8px;
}
.login-form {
  width: 300px;
  border-radius: 5px;
  text-align: center;
  background-color: white; /* Set a light background color */
  padding: 20px; /* Add padding for better spacing */
}

.input-container {
  position: relative;
  margin-bottom: 16px; /* Adjust margin between input fields */
}

.input-container input {
  width: calc(100% - 20px); /* Adjust input width */
  padding: 8px; /* Adjust input padding */
  border: 1px solid transparent; /* Initially set border as transparent */
  border-radius: 5px;
}

.input-container input:focus {
  outline: none;
  border: 1px solid #4a90e2; /* Change border color when input is focused */
}

.error-message {
  color: red;
}
/* Home.css */
body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #000000;
}
  
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    background-color: #333;
    color: white;
    width: 100%;
}
  
header a {
    color: white;
    text-decoration: none;
    margin-right: 20px;
}
  
.content {
    margin-top: 20px;
}

.main-content {
  flex: 1;
  overflow-y: auto;
   /* Adjust as needed */
}


.container {
    display: flex;
    align-items: center; /* Center items vertically */
    
}
  
.logo {
    width: 50px; /* Adjust the width of the logo as needed */
    height: auto; /* Maintain aspect ratio */
    margin-right: 10px; /* Adjust the margin between logo and title */
}
  
.title {
    font-size: 1.5rem; /* Adjust the font size as needed */
}

.footer {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
}
/* HomeScreen.css */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  align-items: start;
}

.home-screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header, .footer {
  background-color: #333;
  color: #fff;
  padding: 10px;
  align-items: center;
  display: flex; /* Add this line */
  justify-content: space-between; /* Add this line */
}
.footer {
  flex-direction: row; /* Add this line */
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}


.card {
  width: 100%;
  max-width: 90%;
  max-height: 90%;
  margin: 20px auto;
  padding: 20px 30px; /* Add vertical and horizontal padding */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow effect */
  border-radius: 10px;
  overflow-y: auto;
  background: linear-gradient(135deg, #4c4d4b, #939692);
}

.card-title {
  position: absolute; /* Position the title absolutely */
  top: 0; /* Position at the top of the card */
  left: 30px; /* Adjust left position as needed */
  z-index: 1; /* Ensure the title is above the content */
  background-color: #fff; /* Set background color to match card background */
  padding: 0 10px; /* Add padding to the title */
  border-radius: 5px; /* Add rounded corners to the title */
}

.card::-webkit-scrollbar {
  width: 0;
  background: transparent; /* Optional: Set background color of scrollbar track */
}

.card-content {
  /* Optionally, you can add styles to the card content */
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #fdf6f6;
}
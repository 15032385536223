.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px; /* Adjust the gap between cards as needed */
  justify-content: center;
  margin: 20px 10px;
}

.card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #0a0a0a;
  overflow: hidden; 
  box-shadow: 0 4px 8px rgba(243, 236, 236, 0.1);
}

.card-logo {
  width: 100%;
  max-height: 150px; /* Adjust the max-height as needed */
  object-fit: contain; /* Use 'contain' to maintain aspect ratio */
  color: #f8f0f0;
}

.card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.card-title {
  margin: 0;
  color: #1b5006;
  font-size: 1.2rem;
}

.card-description {
  margin: 8px 0;
  color: #504a4a;
  font-size: 0.9rem;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card-container-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px; /* Add padding as needed */
}

.card-container-vertical h1 {
  color: #ccc;
}

.card-vertical {
   /* Set the maximum width for each card */
  margin-bottom: 20px; /* Add margin between cards as needed */
  border: 1px solid #ccc;
  border-radius: 8px;
  background: linear-gradient(135deg, #4c4d4b, #939692);
  overflow: hidden;
  flex-direction: row;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 20px;
  width: 100%;
  max-width: 98%;
  padding: 20px;
}

.card-content-vertical {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: left; /* Center content horizontally */
}

.card-logo-vertical {
  width: 30%;
  max-height: 150px; /* Adjust the max-height as needed */
  object-fit: contain; /* Use 'contain' to maintain aspect ratio */
}

.card-title-vertical {
  margin: 16px 0 8px; /* Adjust margin as needed */
  color: #f8f9f7;
  font-size: 1.2rem;
}

.card-description-vertical {
  margin: 8px 0;
  color: #f7f1f1;
  font-size: 0.9rem;
  text-align: left; /* Center text content */
  overflow-wrap: break-word;
}

.spinner-container-vertical {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333333;
  color: white;
  padding: 20px;
  position:relative;
  bottom: 0;
  width: 100%;
}

.contact, .address {
  margin: 0 10px;
}

.contact a {
    color: white;
    text-decoration: none;
}

.centered-text {
  text-align: center;
  flex: 1;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social-icons a {
  margin: 0 10px;
}

.address {
  margin-right: 40px;
}

.contact h2, .address h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.contact p, .address p {
  margin: 0;
}

.card-pricing-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px; /* Add padding as needed */
}

.card-pricing {
  margin-bottom: 20px; /* Add margin between cards as needed */
  border: 1px solid #ccc;
  border-radius: 8px;
  background: linear-gradient(135deg, #4c4d4b, #939692);
  overflow: hidden;
  flex-direction: row;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 20px;
  width: 100%;
  max-width: 98%;
  padding: 20px;
}

.card-content-pricing {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the left */
}

.card-content-pricing h3{
  color: #f8f9f7;
  font-size: 1.2rem;
}

.card-logo-pricing {
  width: 30%;
  max-height: 150px; /* Adjust the max-height as needed */
  object-fit: contain; /* Use 'contain' to maintain aspect ratio */
}

.card-title-pricing {
  margin: 16px 0 8px; /* Adjust margin as needed */
  color: #f8f9f7;
  font-size: 1.2rem;
}  

.pricing-range {
  color: #ffffff; /* White text color */
  margin-bottom: 10px; /* Adjust spacing as needed */
}

.services {
  list-style-type: disc;
  padding: 0;
}

.services li {
  margin-bottom: 8px; /* Adjust spacing between list items */
  color: #ffffff; /* White text color */
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
}

.description {
  align-self: center;
  width: 30%;
  text-align: center;
  line-height: 1.5;
  padding: 20px;
  color: #ffffff;
}